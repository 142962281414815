import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Components/Header/Header';
import Footer from './Components/Footer/Footer';
import HomePage from './Pages/HomePage/HomePage';
import CareerPage from './Pages/CareerPage/CareerPage';
import ConciergeService from './Pages/ConciergeService/ConciergeService';
import StrataCaretaking from './Pages/StrataCaretakingServices/StrataCaretaking';
import StrataCleaningServices from './Pages/StrataCleaningServices/StrataCleaningServices';
import AboutUs from './Pages/AboutUs/AboutUs';
import BuildingManagement from './Pages/BuildingManagement/BuildingManagement';
import ContactUs from './Pages/Contact/ContactUs';
import Blog from './Pages/Blog/Blog';
import BlogDetail from './Pages/BlogDetail/BlogDetail';
import PrivacyPolicyPage from './Pages/PrivacyPolicy/PrivacyPolicyPage';
import JobPosting from './Pages/JobPosting/JobPosting';
import BuildingManagerJobPosting from './Pages/JobPosting/BuildingManagerJobPosting';
import CleanerJobPosting from './Pages/CleanerJobPosting/CleanerJobPosting';
import CaretakerJobPosting from './Pages/CaretakerJobPosting/CaretakerJobPosting';
import ConciergeJobPosting from './Pages/ConciergeJobPosting/ConciergeJobPosting';
import LeadMagnetLandingPage from './Pages/LeadMagnet/LeadMagnetLandingPage';

const App = () => (
  <Router>
  
    <main>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/careers" element={<CareerPage />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:slug" element={<BlogDetail />} />
        <Route path="/service/strata-building-management/" element={<BuildingManagement />} />   
        <Route path="/service/strata-concierge/" element={<ConciergeService />} />        
        <Route path="/service/strata-caretaking/" element={<StrataCaretaking />} />
        <Route path="/service/strata-cleaning/" element={<StrataCleaningServices />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/job-posting/building-manager" element={<BuildingManagerJobPosting/>}/>
        <Route path="/job-posting/cleaner" element={<CleanerJobPosting/>}/>
        <Route path="/job-posting/caretaker" element={<CaretakerJobPosting/>}/>
        <Route path="/job-posting/concierge" element={<ConciergeJobPosting/>}/>
        <Route path="/strata-council-budget-guide/" element={<LeadMagnetLandingPage/>}/>

        
      </Routes>
    </main>
   
  </Router>
);

export default App;