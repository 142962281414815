import React from "react";
import styles from "./Footer.module.css";

function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContent}>
        <div className={styles.companyInfo}>
          <img className={styles.footer} src="https://cdn.builder.io/api/v1/image/assets/TEMP/12a9f7b6edcc4d4e3ca4e2461bd40ec66df7cedddc96f64e5d4519dba106b437?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" alt="Alliance Maintenance Logo" className={styles.footerLogo} />
          <p className={styles.companyDescription}>
            We provide janitorial and maintenance services for strata buildings in the Greater Vancouver.
          </p>
        </div>
        <nav className={styles.footerNav}>
          <div className={styles.navColumn}>
            <h3 className={styles.navTitle}>Company</h3>
            <ul>
              <li><a href="/careers">Join our Team </a><span className={styles.hiringBadge}>Hiring</span></li>
              <li><a href="/about-us">About Us</a></li>
              <li><a href="/contact">Contact</a></li>
              <li><a href="/privacy-policy">Privacy Policy</a></li>
            </ul>
          </div>
          <div className={styles.navColumn}>
            <h3 className={styles.navTitle}>Services</h3>
            <ul>
              <li><a href="/service/strata-cleaning/">Strata Cleaning</a></li>
              <li><a href="/service/strata-caretaking/">Strata Caretaking</a></li>
              <li><a href="/service/strata-concierge">Strata Concierge</a></li>
              <li><a href="/service/strata-building-management/">Strata Building Management</a></li>
              <li><a href="/contact">Request a Quote</a></li>
            </ul>
          </div>
        </nav>
      </div>
      <div className={styles.socialConnect}>
        <h3 className={styles.socialTitle}>Connect with us</h3>
        <div className={styles.socialIcons}>
          <a href="https://www.facebook.com/alliancecleans/" target="blank" aria-label="Facebook">
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/0f6b125562c01a3385583b91ea21760b4583afdead402c52592208e0051ad145?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" alt="" className={styles.socialIcon} />
          </a>
          <a href="https://www.instagram.com/alliancecleans/" target="blank" aria-label="Instagram">
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/a656cf57ef3f1e10c9e71af019f51187e7f5576eb84050730fb399e74652fda1?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" alt="" className={styles.socialIcon} />
          </a>
          <a href="https://www.linkedin.com/company/alliance-maintenance-ltd-/?originalSubdomain=ca" target="blank" aria-label="LinkedIn">
            <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/8acdcf35d82fa1df8548e45881036adaae5cb7d47e842abda6f978d633ef0f85?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" alt="" className={styles.socialIcon} />
          </a>
        </div>
      </div>
      <div className={styles.horizontalLine}></div>
      <div className={styles.copyright}>
        Copyright © 2024 Alliance Maintenance Ltd. All rights reserved.
      </div>
    </footer>
  );
}

export default Footer;