import React, { useEffect, useState } from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { useSearchParams } from "react-router-dom";
import "./Blog.css";
import "./Pagination.css";
import { Link } from "react-router-dom";

const Blog = () => {
  const baseUrl = 'https://blog.admin.alliancecleans.com';
  const [ blogs, setBlogs ] = useState([]);
  const [ categories, setCategories] = useState([]);
  const [ featuredBlogs, setFeaturedBlogs ] = useState({});
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ count, setCount ] = useState(0);
  const page_size = 6;

  const currentPage = parseInt(searchParams.get('page')) || 1;
  const selectedCategory = searchParams.get('category') || '';
  const selectedOrdering = searchParams.get('ordering') || '';

  const fetchCategories = () => {
    fetch(`${baseUrl}/api/categories`
    ).then(response => response.json()
    ).then(data => setCategories(data)
    ).catch(err => console.log(err));
  }

  const fetchBlogs = () => {
    const params = new URLSearchParams({
      page: currentPage,
      category: selectedCategory,
      ordering: selectedOrdering,
    });

    fetch(`${baseUrl}/api/blogs?is_featured=False&${params}`
    ).then((response) => {
      if (response.status != 200){
        throw new Error(`Request failed with status ${response.status}`);
      }
      return response.json();
    }
    ).then((data) => {
      setCount(data.count);
      setBlogs(data.results);
    }).catch((err) => {
      console.log(err);
      setCount(1);
      setBlogs(1);
    });
  }

  useEffect(() => {
    fetch(`${baseUrl}/api/blogs?is_featured=True`
    ).then(response => response.json()
    ).then((data) => {
      setFeaturedBlogs(data.results[0]);
    }).catch((err) => console.log(err));

    fetchCategories();
  }, []);

  useEffect(() => {
    fetchBlogs();
  }, [searchParams]);

    // Handle filter change
    const handleFilterChange = (e) => {
      setSearchParams((prev) => {
        const updated = new URLSearchParams(prev);
        updated.set(e.target.name, e.target.value);
        // updated.set('page', 1); // Reset to page 1 on filter change
        return updated;
      });
    };
  
    // Handle pagination
    const handlePageChange = (page) => {
      setSearchParams((prev) => {
        const updated = new URLSearchParams(prev);
        updated.set('page', page);
        return updated;
      });
    };

      // Render pagination buttons
  const renderPagination = () => {
    const pages = [];
    for (let i = 1; i <= Math.ceil(count/page_size); i++) {
      pages.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={i === currentPage ? 'page-button active' : 'page-button'}
        >
          {i}
        </button>
      );
    }
    return <div className="pagination">{pages}</div>;
  };
  return (
    <div>
      <Header />
      <div style={{ marginTop: '90px'}}>
        {featuredBlogs &&
        <div className="featured-container">
          <div className="featured-top">
            <div className="featured-image">
              <img src={ featuredBlogs.title_photo } />
            </div>
            <div className="featured-details">
              <p className="featured-text">FEATURED ARTICLE</p>
              <h3 className="featured-title">{ featuredBlogs.title }</h3>
              <p className="featured-date">{ featuredBlogs.published_at }</p>
              <p className="featured-description">{ featuredBlogs.description }</p>
              <div className="read-btn-container">
                <Link className="read-btn" to={`/blog/${featuredBlogs.slug}`}>
                  <p>Read More</p>
                  <span>
                    <img 
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/479e7014a54951dddf3dc6b8b25496462baea493d14b2acc7faaa7e4227ababd?placeholderIfAbsent=true&apiKey=b2e7542990074f4e82899d052e66ed8f" 
                      alt="" 
                      className="read-btn-img"
                    />
                  </span>
                </Link>
              </div>
            </div>
          </div>
          <div className="filter-container">
            <select onChange={handleFilterChange} name='category'>
              <option>Choose By Category</option>
              {categories.map((item, index) => {
                return (<option key={index} value={item.title}>{item.title}</option>);
              })}
            </select>
            <select onChange={handleFilterChange} name="ordering">
              <option>Sort By</option>
              <option value="-published_at">Published Date (Latest to Oldest)</option>
              <option value="published_at">Published Date (Oldest to Latest)</option>
            </select>
          </div>
        </div>
        }
        <div className="featured-curve"></div>
        <div className="blog-list-container">
          {blogs && blogs.map((item, index) => {
            return (
              <Link to={`/blog/${item.slug}`} key={index}>
                <div className="blog-card">
                  <div className="blog-img">
                    <img src={ item.title_photo } />
                  </div>
                  <div className="blog-category-date">
                    <p className="card-category">{item.category.title}</p>
                    <p className="card-date">{item.published_at}</p>
                  </div>
                  <div className="blog-title">
                    <p>{item.title}</p>
                  </div>
                  <div className="blog-description">
                    <p>{ item.description }</p>
                  </div>
                </div>
              </Link>
            );
          })}
        </div>
        {renderPagination()}
      </div>
      <Footer />
    </div>
  );
}

export default Blog;