import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import "./BlogDetail.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";


const BlogDetail = () => {
    const baseUrl = 'https://blog.admin.alliancecleans.com';
    const params = useParams();
    const [ blog, setBlog ] = useState({});
    const [ relatedBlogs, setRelatedBlogs ] = useState([]);

    const fetchBlogs = () => {
        fetch(`${baseUrl}/api/blog/${params.slug}`
        ).then(response => {
            return response.json();
        }
        ).then(data => {
            setBlog(data);
        }
        ).catch(err => console.log(err));
    }

    const fetchRelatedBlogs = (exclude_id, category_id) => {
        fetch(`${baseUrl}/api/blogs?page=1&page_size=3&category=${category_id}&exclude=${exclude_id}`
        ).then(response => response.json()
        ).then(data => setRelatedBlogs(data.results)
        ).catch(err => console.log(err));
    }

    useEffect(() => {
        fetchBlogs();
    }, [params]);

    useEffect(() => {
        if (Object.keys(blog).length != 0) {
            fetchRelatedBlogs(blog.id, blog.category.title);
        }
    }, [blog]);

    return (
        <>
            { blog.category &&
                <>
                    <Helmet>
                        { blog.title && <title>{ blog.title }</title> }
                        { blog.meta_description && <meta name="description" content={ blog.description } />}
                        { blog.keywords && <meta name="keywords" content={ blog.keywords } />}

                        { blog.title && <meta property="og:title" content= { blog.title } />}
                        { blog.meta_description && <meta property="og:description" content={ blog.description } /> }
                        { blog.title_photo && <meta property="og:image" content= { blog.title_photo } />}

                        { blog.title && <meta property="twitter:title" content= { blog.title } />}
                        { blog.meta_description && <meta property="twitter:description" content={ blog.description } /> }
                        { blog.title_photo && <meta property="twitter:image" content= { blog.title_photo } />}
                    </Helmet>
                </>
            }
            <Header />
            <div style={{ marginTop: '90px'}}>
                { blog.category &&
                <div className="info-section">
                    <div className="info-left">
                        <p className="info-category">{ blog.category.title }</p>
                        <p className="info-title">{ blog.title }</p>
                        <p className="info-date">{ blog.published_at }</p>
                        <p className="info-description">{ blog.description }</p>
                    </div>
                    <div className="info-right">
                        <img src={ blog.title_photo }/>
                    </div>
                </div>
                }
                <div className="content-section" dangerouslySetInnerHTML={{ __html: blog.content }}>
                </div>
                <div className="featured-curve"></div>
                <div className="related-section">
                    <h1>Related Articles</h1>
                    <div className="blog-list-container">
                        {relatedBlogs && relatedBlogs.map((item, index) => {
                            return (
                                <Link to={`/blog/${item.slug}`} key={ index }>
                                    <div className="blog-card">
                                        <div className="blog-img">
                                            <img src={ item.title_photo }/>
                                        </div>
                                        <div className="blog-category-date">
                                            <p className="card-category">{ item.category.title }</p>
                                            <p className="card-date">{ item.date }</p>
                                        </div>
                                        <div className="blog-title">
                                            <p>{ item.title }</p>
                                        </div>
                                        <div className="blog-description">
                                            <p>{ item.description }</p>
                                        </div>
                                    </div>
                                </Link>
                            );
                        })}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default BlogDetail;